
<template>
<div>
  <div class="c-buttons-row--container">
    <div class="c-buttons-row">
      <div class="main-menu"><router-link to="/records">Журнал счетов</router-link></div>
      <div class="main-menu"><router-link to="/about">О проекте</router-link></div>
      <div class="main-menu"><router-link to="/login">Вход</router-link></div>
    </div>
    <div class="c-buttons-row--container-after">
    </div>
  </div>
  <div class="container">
    <div class="login-menu" @keyup.enter="persist">
      <font size = 3>
      <h1>Вход в систему</h1>
      <div class="login-content" align="right">
        <label>Логин</label>&nbsp;
        <input v-model="username">
      </div>
      <div class="login-content" align="right">
        <label>Пароль</label>&nbsp;
        <input type="password" v-model="password">
      </div>
      <div class="login-content" align="center">
        <button @click="persist" >Войти</button>
      </div>
    </font>
    </div>
  </div>
</div>
</template>

<script>

import {mapActions} from 'vuex';

export default {
  name: "v-login",
  components: {
  },
  data() {
    return {
      username:"",
      password:"",
    };
  },
  props: {
    account_id: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
  },
  methods: {
    ...mapActions([
      "CHECK_USER",
    ]),

    persist() {
      if (this.username != "" && this.password !=""){
        this.CHECK_USER({ thisobj:this, username:this.username, password:this.password });
      }
    },
  },
  mounted() {
  },
  updated(){
  },
  beforeDestroy() {
  },
};
</script>

<style type="text/css">
</style>
